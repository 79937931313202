var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("data-map-package-launcher", {
        attrs: {
          versions: ["data-map-long-term-support"],
          "form-mode": "URL",
          "source-item": _vm.companyDetailsItem,
          "parent-loading": _vm.loading,
          "validate-and-submit": _vm.validateAndSubmit,
        },
        on: {
          loading: function ($event) {
            _vm.loading = $event
          },
          "validate-and-submit-result": _vm.handleValidateAndSubmitResult,
        },
      }),
      _c(
        "div",
        { staticClass: "button-div" },
        [
          _c(
            "b-button",
            {
              staticClass: "button-cancel",
              attrs: {
                "aria-label": "cancel button",
                disabled: _vm.disableSubmitButton || _vm.loading,
              },
              on: { click: _vm.cancelEditMode },
            },
            [_vm._v("\n      Cancel\n    ")]
          ),
          _c(
            "b-button",
            {
              staticClass: "button-save",
              attrs: {
                "aria-label": "save changes button",
                disabled: _vm.disableSubmitButton || _vm.loading,
              },
              on: { click: _vm.saveDetails },
            },
            [_vm._v("\n      Looks Good!\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }