<template>
  <div>
    <data-map-package-launcher
      :versions="['data-map-long-term-support']"
      form-mode="URL"
      :source-item="companyDetailsItem"
      :parent-loading="loading"
      :validate-and-submit="validateAndSubmit"
      @loading="loading = $event"
      @validate-and-submit-result="handleValidateAndSubmitResult"
    />
    <div class="button-div">
      <b-button
        class="button-cancel"
        aria-label="cancel button"
        :disabled="disableSubmitButton || loading"
        @click="cancelEditMode"
      >
        Cancel
      </b-button>
      <b-button
        class="button-save"
        aria-label="save changes button"
        :disabled="disableSubmitButton || loading"
        @click="saveDetails"
      >
        Looks Good!
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { createOrFindClientInteractionLog } from '@/common/modules/clientInteractionLog'

export default {
  name: "EditDetails",

  components: {
    DataMapPackageLauncher: () => import('@/components/DataMapPackageLauncher'),
  },

  props: {
    companyDetailsItem: {
      type: Object,
      default: () => {},
    },
    schemaKey: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      loading: false,
      validateAndSubmit: false,
    }
  },

  computed: {
    ...mapGetters('dataMap', [
      'disableSubmitButton',
    ]),
    ...mapGetters('stagelineCompanyOverview', [
      'company',
    ]),
    companyId() {
      return this.companyDetailsItem?.company?.id || null
    },
  },
  methods: {
    ...mapActions('companies', ['loadById']),
    ...mapActions('stagelineCompanyOverview', [
      'processCompanyDetails',
    ]),

    cancelEditMode() {
      this.$emit('cancel-edit-mode')
    },

    async saveDetails() {
      this.loading = true
      this.validateAndSubmit = !this.validateAndSubmit
    },
    async handleValidateAndSubmitResult(result) {
      if (result.validation.isValid) {
        if (result.submit.success) {
          await this.processCompanyDetails()
          if (this.companyId) await this.loadById({ id: this.companyId })
          this.logClientInteraction()
          this.cancelEditMode()
        }

        if (result.submit.message !== 'backend-group-errors') {
          this.displaySubmissionPopup(
            result.submit.message,
            result.submit.success ? 'Success' : 'Error',
            result.submit.success ? 'success' : 'danger'
          )
        }
      }
      this.loading = false
    },
    displaySubmissionPopup(message, title, variant) {
      this.$bvToast.toast(message, { title, variant, solid: true })
    },
    logClientInteraction() {
      createOrFindClientInteractionLog({
        companyId: this.companyId,
        category: 'stageline',
        subCategory: 'business-overview',
        interaction: {
          type: 'button',
          action: 'save',
          name: 'update-company-details',
          section: this.schemaKey,
        },
        completeLog: true,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.button-div {
  width: 100%;
  text-align: center;
}
.button-cancel {
  color: $ct-ui-primary !important;
  background: white;
  border: 0;

  &:hover {
    background: $ct-ui-primary-light;
  }
}

.button-save {
  background: $ct-ui-primary;
  border-color: $ct-ui-primary;

  &:hover {
    background: $ct-ui-primary-dark;
    border-color: $ct-ui-primary-dark;
  }
}
</style>
